.App {
  background: url(./back1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.Logo {
  width: 73%;
  height: auto;
}
